<template>
  <Lock :wiki="wiki" v-on:ready="load()">
    <Overlay :error="error" :reason="reason" :action="action" v-on:ok="load()" class="uk-height-viewport" v-if="overlay">
    </Overlay>
    <article class="uk-height-viewport uk-flex uk-flex-column uk-flex-middle" v-if="renamed">
      <SiteHeader class="uk-margin-top"></SiteHeader>
      <section class="uk-width-xlarge uk-padding">
        <ul class="uk-breadcrumb uk-flex-left">
          <li><router-link to="/home">home</router-link></li>
          <li><router-link :to="{ name: 'projects', params: { wiki }}">{{ wiki }}</router-link></li>
          <li><router-link :to="{ name: 'project', params: { wiki, path }}">{{ path }}</router-link></li>
          <li><span>{{ label }}</span></li>
        </ul>
        <form class="uk-clearfix" v-on:submit.prevent="submit()">
          <h2>{{ label }}</h2>
          <p>Confirm the release</p>
          <ul class="uk-list" v-if="renamed.length">
            <li v-for="item of renamed" :key="item.path">
              <span v-if="item.origin">rename <b>{{ item.path }}</b> from <b>{{ item.origin }}</b></span>
              <span v-else>add <b>{{ item.path }}</b></span>
            </li>
          </ul>
          <SubmitFooter
              :error="error"
              :active="active"
              label="release">
          </SubmitFooter>
        </form>
      </section>
      <div class="uk-flex-1"></div>
      <SiteFooter class="uk-flex-none uk-flex-bottom"></SiteFooter>
    </article>
    <Migrate v-else-if="published"
      :key="key"
      :project="path"
      :local="artifact"
      :remote="published"
      v-on:active="reason = 'comparing'"
      v-on:conflict="reason = null"
      v-on:ok="review">
    </Migrate>
  </Lock>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { createContext } from "@/scripts/canvas.js";

import Lock from '@/components/Lock.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import SubmitFooter from '@/components/SubmitFooter.vue';
import Overlay from '@/components/Overlay.vue';

export default {
  name: 'ReleaseTool',
  components: {
    Overlay,
    Lock,
    SiteHeader,
    SiteFooter,
    SubmitFooter,
  },
  props: {
    wiki: String,
    path: String,
    label: String,
  },
  data() {
    return {
      reason: null,
      error: null,
      bus: new Vue(),
      artifact: null,
      links: null,
      active: null,
      published: null,
      action: null,
      failure: null,
      renamed: null,
      key: 1,
    };
  },
  mounted() {
    window.addEventListener('beforeunload', this.event);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.event);
  },
  beforeRouteLeave (to, from, next) {
    if (this.published) {
      const answer = window.confirm('Do you really want to leave? Your review will not be saved!');
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  computed: {
    overlay() {
      return !this.renamed && (this.error || this.reason || !this.published);
    },
    ...mapState('api', ['service', 'help']),
  },
  methods: {
    event(evt) {
      if (this.published) {
        evt.preventDefault();
        evt.returnValue = '';
      }
    },
    async load() {
      this.reason = 'loading release candidate';
      this.error = null;
      this.action = null;

      try {
          let data = await Promise.all([
            this.service.getFromJsonAsync(
              `/acorn/consumer/module/${this.wiki}/${this.path}/tag?label=${this.label}`
            ),
            this.service.getFromJsonAsync(
              `/acorn/consumer/module/${this.wiki}/${this.path}`
            )]);

          this.artifact = data[0];
          this.links = this.service.from(data[1]);
          // if (data[1].published == null) {
            this.review(new Map());
          // } else {
          //   this.published = await this.service.getFromJsonAsync(
          //     `/acorn/consumer/module/${this.wiki}/${this.path}/tag?version=${data[1].published}`
          //   );
          //   this.key++;
          //   this.reason = null;
          // }
      } catch (err) {
          this.error = err.message ?? 'An unexpected error occurred';
          this.action = 'load';
      }
    },

    async loadTag(label, version) {
      const context = createContext(this.bus, this.path, true);
      const params = [];
      if (label) {
        params.push(`label=${label}`);
      }
      if (version) {
        params.push(`version=${version}`);
      }
      const tag = await this.service.getFromJsonAsync(
        `/acorn/consumer/module/${this.wiki}/${this.path}/tag?${params.join(
          "&"
        )}`
      );
      const promises = new Map();
      for (const [key, layer] of context.inject(tag.model, ...tag.requirements)) {
        promises.set(key, Promise.resolve(layer));
      }
      return [
        context,
        this.service.from(tag),
        promises,
      ]
    },


    review(renamed) {
      this.renamed = [];
      var keys = [...renamed.keys()]
      keys.sort();
      this.renamed = keys.map(path => { return { path, origin: renamed.get(path) }; })
      this.reason = null;
      this.published = null;
    },
    async submit() {
      this.active = true;
      this.error = null;
      try {
          await this.links.asReleaseArtifactUri().postAsync(
            this.artifact.artifact_id,
            this.renamed);
          this.$router.push({ name: 'project', params: { wiki: this.wiki, path: this.path }})
      } catch (err) {
        this.error = err.message ?? 'An unexpected error occurred';
        if (!err.status) {
          console.error(err);
        } else {
          var tree = this.$error(err.status, err.error);
          if (tree.assert_claimed()) {
            this.error = 'the form cannot display an important error';
          }
        }
      } finally {
          this.active = false;
      }
    },
  },
}
</script>